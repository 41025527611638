import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroSplit from "../components/Hero/HeroSplit";
import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="" headerHasBorder={false}>
         <SearchEngineOptimization
            title="About Smart Accounting Hub | Small Business Bookkeeping"
            description="Smart Accounting Hub is your gateway to advanced bookkeeping, accrual-based accounting, and management or managerial accounting. Learn more here."
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroSplit
            image={data.hero.childImageSharp.gatsbyImageData}
            padding="pt-6 md:pt-16 pb-0"
            hasContainer={true}
            textWidth="1/2"
            imageWidth="1/2"
            imageAlignment="right"
         >
            <h1>The Need for Smart Accounting</h1>
            <p className="mb-0">
               Many small businesses in the early stages are primarily concerned with compliance – paying bills, getting paid, recording transactions,
               ensuring payroll accuracy, and following state and federal regulations. But at some point, your business will cross a threshold and
               you’ll begin to place more emphasis on the need for timely, accurate financial reports and intelligence. That’s where we come in.
            </p>
         </HeroSplit>

         <section className="pt-20 md:pt-32 mb-20 md:mb-32">
            <div className="container">
               <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-10 lg:gap-x-20 items-center">
                  <div>
                     <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} alt="Intro" />
                  </div>
                  <div>
                     <h2>Smart Accounting for Your Small Business</h2>
                     <p className="mb-0">
                        Smart Accounting Hub is your gateway to advanced bookkeeping, accrual-based accounting, and management or managerial
                        accounting to help you make data-driven decisions. Owner Juan Ramirez offers personal attention that larger bookkeeping
                        services can’t handle to help your business thrive. When you need smarter accounting for your business in San Diego and
                        beyond, we’re here to help.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <Testimonials className="pb-20 md:pb-32" />

         <WhyUs className="py-20 md:py-32" contentAlignment="text-center mx-auto" />

         <CallToAction />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/About.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/About.jpg" }) {
         publicURL
      }
      hero: file(relativePath: { eq: "about/1.0 About us-hero.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      intro: file(relativePath: { eq: "about/2.0 Who We Are.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
